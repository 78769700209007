<template>
    <div>
        <div class="title">权限管理</div>
        <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-background="#fff"
        :data="tableData"
        border
        stripe
        style="width: 100%">
            <el-table-column
                prop="title"
                label="权限">
            </el-table-column>
            <el-table-column
                prop="path"
                label="节点路径">
            </el-table-column>
            <el-table-column
                label="图标">
                <template scope="scope">
                    <el-image
                        v-if="scope.row.icon"
                        style="width: 50px; height: 50px"
                        :src="scope.row.icon"
                        fit="cover">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column
                prop="create_time"
                label="状态">
                <template slot-scope="scope">
                    <div class="table-ban" v-if="scope.row.status==0">禁用</div>
                    <div class="table-star" v-else>启用</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="create_time"
                label="添加时间">
            </el-table-column>
        </el-table>

        <el-pagination
        class="table-limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageArray"
        :page-size= this.limit.page_size
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { get_scope } from '@/api/scope.js'
export default {
    name:'Scope',
    data(){
        return {
            pageArray:this.$store.state.pageArray,
            limit:{
                page:1,
                page_size:10, 
            },
            loading:true,
            tableData:[],
            total:0,
            currentPage:1
            
        };
        
    },
    created(){
        this.getScopeList()
    },
    methods:{
        // 获取权限列表
        getScopeList(){
            get_scope(this.limit).then(res=>{
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },

        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            this.getScopeList()
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            this.getScopeList()
        }
    }    
}

</script>

<style scoped>
.title{
    font-size: 24px;
    font-weight: bold;
    color: #428CFF;
    line-height: 90px;

}

</style>