import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 权限组
 **************************************/
/**
 * 获取权限列表
 */
export function get_scope(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/auth/rule',
        method: 'get',
        params:data
    })
}

/**************************************
 * 角色组
 **************************************/

 /**
 * 角色更新
 */
export function updata_rule(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/auth/role/0',
      method: 'put',
      data
    })
}

/**
 * 所有权限列表
 */
export function get_rule_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/auth/role/rule/list',
      method: 'get',
      data
    })
}

/**
 * 角色查看
 */
export function see_rule(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/auth/role/0',
      method: 'get',
      params:data
    })
}



/**
 * 角色列表
 */
export function get_role(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/auth/role',
      method: 'get',
      params:data
    })
}

/**
 * 角色添加
 */
export function add_role(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/auth/role',
      method: 'post',
      data
    })
}

/**
 * 角色删除
 */
export function del_rule(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/auth/role/0',
      method: 'delete',
      data
    })
}
